import React from 'react'

function ErrorMsg() {
  return (
    <div>
        <p className='form-error-msg'>Please complete the question before moving forward</p>
    </div>
  )
}

export default ErrorMsg