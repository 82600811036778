import React from "react";
import arrowicon from "../../util/imageIcons/Send (1).png";
import { Link } from "react-router-dom";
const BannerSmall = () => {
  return (
    <div className="BannerSmall">
      <div className="content common_width">
        <div className="presentation">
          <h1>Not quite a founder yet?</h1>
          <p>
            Do you have a great idea, and don’t 
            know the next steps to turn that idea into a high-performing, 
            venture-backable business? We can help with that.
          </p>
        </div>

        <Link to="/waitlist" className="button">
          Join our waitlist
          <img src={arrowicon} alt="" />
        </Link>
      </div>
    </div>
  );
};
export default BannerSmall;
