import React from "react";

const BlackFounderConnectionCard = ({ title, body }) => {
  return (
    <div>
      <h1>As a Founder</h1>
      <p>
        You receive guidance through our platform on how to start 
        and grow your business. We help you create a compete data story, located all in one place. 
      </p>
      <p>
        For new founders, we assist with 
        incorporating, issuing your EIN, business banking, 
        and all the steps needed to become a venture-backable business.
      </p>
      <p>
        For established founders, we help you manage and organize your business for grown, and fundraising.
      </p>       
    </div>
  );
};
export default BlackFounderConnectionCard;
