import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "AlterwaveBold",
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "48px",
    letterSpacing: "-0.02em",
    textAlign: "center",
    color: "#000",
  },
  bodyText: {
    borderRadius: "8px",
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "-0.03em",
    textAlign: "center",
  },
  spacing: {
    padding: "20px"
  }
}));

const WhyPitchBlack  = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid container alignItems="center">
            <Grid item xs={12}> 
            <Typography className={classes.title}>We have a strong mission in the black venture community</Typography>
            </Grid>

            <Grid container alignItems="center" className={classes.spacing}>
              <Grid item xs={2} width="20"></Grid>
              <Grid item xs={8}>
              <Typography className={classes.bodyText}>
              In the last couple of years, we have seen the most capital go to black-owned businesses than ever before! 
              However, this has been a direct affect of venture capital dollars increasing accross the entire ecosystem. 
              In fact, over the last two years, the percentage of capital that went to black founders has decreased 
              resulting in only 1% of total venture capital.
              </Typography>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>

            <Grid Grid container alignItems="center" className={classes.spacing}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
            <Typography className={classes.bodyText}>
              It is our mission to close the capital wealth gap by activating and engaging black investors in the 
              marketplace while distributing funds to the black community through black ventures and start-up investments.
            </Typography>
            </Grid>
            <Grid item xs={2}></Grid>
            </Grid>

            <Grid container alignItems="center" className={classes.spacing}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
            <Typography className={classes.bodyText}>
              A large piece of our business is education. We have found that there is a 
              significant barrier obfuscating the black investor's ability to invest. There is an obscure 
              understanding of deal flow that we wish to make clear. We are committed to creating software 
              that creates a clear and simplified deal-flow process and investment process while intuitively 
              delivering education where and when it is needed, to help black investors better understand 
              how to deploy capital into black ventures. We will also create a similar business development 
              model for black venture founders, around the nuances that come with being a black founder, 
              and how to navigate managing, and raising funds for their start-ups. By automating the deal-flow process, 
              we will also utilize our technology to simplify the process while clarifying steps needed to take 
              from business conception to close of fundraising. Our thesis is that creating an easier barrier of 
              entry for black founders and investors will drastically increase the number on both sides of the marketplace, 
              which will assist in closing the black and brown wealth gap.
            </Typography>
            </Grid>
            <Grid item xs={2}></Grid>
            </Grid>

            <Grid container alignItems="center" className={classes.spacing}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
            <Typography className={classes.bodyText}>
              We have identified the friction points in engaging black investors to be discovery and deal-flow. 
              To mitigate this, we’ve created a process to recruit, vet, and present companies to investors. 
              We have built web and mobile software that allows investors to discover investment-ready companies. 
              Investors can indicate interest through the software, at which point we facilitate contact between the two. 
              If all goes well, founders can grant access to their interactive executive summary, 
              where investors can take a deeper look into their company’s cap tables, financials, milestones, 
              and more in an industry standardized format. This will help expedite the due diligence period by 
              creating a common language and expectation between investors and founders. We also automate the deal flow 
              process by generating SAFE notes, and term sheet templates. This will significantly minimize the friction 
              that both sides of the market encounter while shortening the time it takes from initial contact to deal-close. 
              Based on conversations we’ve had with founders and investors, it seems that pipeline and relationships have 
              been a major roadblock in sourcing black-founded ventures. Our solution not only creates a connector between 
              both players, but also helps to create new players, as we educate new high net worth individuals, and 
              encourage them to activate their capital in the marketplace.
            </Typography>
            </Grid>
            <Grid item xs={2}></Grid>
            </Grid>
      </Grid>
      
      
      
     
    </div>
  );
};
export default WhyPitchBlack;
