import React from "react";
import Form from "../Form/multiStepForm/Form";

const Questionnaire = () => {
  return <div>

    <Form/>

    </div>;
};

export default Questionnaire;
