import React from "react";
import { Link } from "react-router-dom";

const ThankYou  = () => {
  return (
    <div className="form_wrapper">
      <h1>Thank you for joining</h1>
      <p>
        Sit tight, we'll be in touch once some space has opened up!
      </p>
    </div>
  );
};
export default ThankYou;
