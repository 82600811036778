import Dashboard from "../pages/dashboard";
import ComingSoon from "../components/ComingSoon";
import Company from "../pages/companyInfo";
import Document from "../pages/documentLibrary";
import Captable from "../pages/captable";
import Team from "../pages/team";
import FundRaise from "../pages/fundRaise";

import DashboardIcon from "./imageIcons/element-4.png";
import companyInfoIcon from "./imageIcons/company.svg";
import teamIcon from "./imageIcons/briefcase.png";
import catableIcon from "./imageIcons/graph.png";
import libraryIcon from "./imageIcons/document-text.png";
import settingIcon from "./imageIcons/setting-2.png";

export const Routes = [
  {
    exact: true,
    path: "/dashboard",
    component: Dashboard,
    title: "Dashboard",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.5 10.9V4.1C21.5 2.6 20.86 2 19.27 2H15.23C13.64 2 13 2.6 13 4.1V10.9C13 12.4 13.64 13 15.23 13H19.27C20.86 13 21.5 12.4 21.5 10.9Z"
          fill="#1F1D36"
        />
        <path
          d="M11 13.1V19.9C11 21.4 10.36 22 8.77 22H4.73C3.14 22 2.5 21.4 2.5 19.9V13.1C2.5 11.6 3.14 11 4.73 11H8.77C10.36 11 11 11.6 11 13.1Z"
          fill="#1F1D36"
        />
        <path
          d="M21.5 19.9V17.1C21.5 15.6 20.86 15 19.27 15H15.23C13.64 15 13 15.6 13 17.1V19.9C13 21.4 13.64 22 15.23 22H19.27C20.86 22 21.5 21.4 21.5 19.9Z"
          fill="#1F1D36"
        />
        <path
          d="M11 6.9V4.1C11 2.6 10.36 2 8.77 2H4.73C3.14 2 2.5 2.6 2.5 4.1V6.9C2.5 8.4 3.14 9 4.73 9H8.77C10.36 9 11 8.4 11 6.9Z"
          fill="#1F1D36"
        />
      </svg>
    ),
  },
  {
    exact: true,
    path: "/dashboard/company_info",
    component: Company,
    title: "Company Info",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.19 0H5.81C2.17 0 0 2.17 0 5.81V14.19C0 17.83 2.17 20 5.81 20H14.19C17.83 20 20 17.83 20 14.19V5.81C20 2.17 17.83 0 14.19 0ZM7.97 12.9L5.72 15.15C5.57 15.3 5.38 15.37 5.19 15.37C5 15.37 4.8 15.3 4.66 15.15L3.91 14.4C3.61 14.11 3.61 13.63 3.91 13.34C4.2 13.05 4.67 13.05 4.97 13.34L5.19 13.56L6.91 11.84C7.2 11.55 7.67 11.55 7.97 11.84C8.26 12.13 8.26 12.61 7.97 12.9ZM7.97 5.9L5.72 8.15C5.57 8.3 5.38 8.37 5.19 8.37C5 8.37 4.8 8.3 4.66 8.15L3.91 7.4C3.61 7.11 3.61 6.63 3.91 6.34C4.2 6.05 4.67 6.05 4.97 6.34L5.19 6.56L6.91 4.84C7.2 4.55 7.67 4.55 7.97 4.84C8.26 5.13 8.26 5.61 7.97 5.9ZM15.56 14.62H10.31C9.9 14.62 9.56 14.28 9.56 13.87C9.56 13.46 9.9 13.12 10.31 13.12H15.56C15.98 13.12 16.31 13.46 16.31 13.87C16.31 14.28 15.98 14.62 15.56 14.62ZM15.56 7.62H10.31C9.9 7.62 9.56 7.28 9.56 6.87C9.56 6.46 9.9 6.12 10.31 6.12H15.56C15.98 6.12 16.31 6.46 16.31 6.87C16.31 7.28 15.98 7.62 15.56 7.62Z"
          fill="#1F1D36"
        />
      </svg>
    ),
  },
  {
    exact: true,
    path: "/dashboard/team",
    component: Team,
    title: "Team",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.09 6.97953C20.24 6.03953 18.82 5.56953 16.76 5.56953H16.52V5.52953C16.52 3.84953 16.52 1.76953 12.76 1.76953H11.24C7.48004 1.76953 7.48004 3.85953 7.48004 5.52953V5.57953H7.24004C5.17004 5.57953 3.76004 6.04953 2.91004 6.98953C1.92004 8.08953 1.95004 9.56953 2.05004 10.5795L2.06004 10.6495L2.13749 11.4628C2.15176 11.6126 2.23242 11.7479 2.35831 11.8303C2.59812 11.9872 2.99946 12.2459 3.24004 12.3795C3.38004 12.4695 3.53004 12.5495 3.68004 12.6295C5.39004 13.5695 7.27004 14.1995 9.18004 14.5095C9.27004 15.4495 9.68004 16.5495 11.87 16.5495C14.06 16.5495 14.49 15.4595 14.56 14.4895C16.6 14.1595 18.57 13.4495 20.35 12.4095C20.41 12.3795 20.45 12.3495 20.5 12.3195C20.8968 12.0953 21.3083 11.819 21.6835 11.5484C21.7965 11.4668 21.8688 11.3408 21.8842 11.2023L21.9 11.0595L21.95 10.5895C21.96 10.5295 21.96 10.4795 21.97 10.4095C22.05 9.39953 22.03 8.01953 21.09 6.97953ZM13.09 13.8295C13.09 14.8895 13.09 15.0495 11.86 15.0495C10.63 15.0495 10.63 14.8595 10.63 13.8395V12.5795H13.09V13.8295ZM8.91004 5.56953V5.52953C8.91004 3.82953 8.91004 3.19953 11.24 3.19953H12.76C15.09 3.19953 15.09 3.83953 15.09 5.52953V5.57953H8.91004V5.56953Z"
          fill="#1F1D36"
        />
        <path
          d="M20.8733 13.7339C21.2269 13.5656 21.6342 13.8459 21.5988 14.2359L21.2398 18.1897C21.0298 20.1897 20.2098 22.2297 15.8098 22.2297H8.18984C3.78984 22.2297 2.96984 20.1897 2.75984 18.1997L2.41913 14.4519C2.38409 14.0664 2.78205 13.7865 3.13468 13.946C4.2741 14.4615 6.37724 15.3761 7.67641 15.7164C7.84072 15.7594 7.97361 15.877 8.04556 16.0309C8.65253 17.329 9.96896 18.0197 11.8698 18.0197C13.752 18.0197 15.085 17.3024 15.694 16.0011C15.766 15.8471 15.8991 15.7296 16.0635 15.6863C17.443 15.3233 19.6816 14.301 20.8733 13.7339Z"
          fill="#1F1D36"
        />
      </svg>
    ),
  },
  {
    exact: true,
    path: "/dashboard/captable",
    component: Captable,
    title: "Captable",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6702 6.95039C21.0302 4.78039 19.2202 2.97039 17.0502 2.33039C15.4002 1.85039 14.2602 1.89039 13.4702 2.48039C12.5202 3.19039 12.4102 4.47039 12.4102 5.38039V7.87039C12.4102 10.3304 13.5302 11.5804 15.7302 11.5804H18.6002C19.5002 11.5804 20.7902 11.4704 21.5002 10.5204C22.1102 9.74039 22.1602 8.60039 21.6702 6.95039Z"
          fill="#1F1D36"
        />
        <path
          d="M18.9104 13.3601C18.6504 13.0601 18.2704 12.8902 17.8804 12.8902H14.3004C12.5404 12.8902 11.1104 11.4601 11.1104 9.70015V6.12015C11.1104 5.73015 10.9404 5.35015 10.6404 5.09015C10.3504 4.83015 9.95039 4.71015 9.57039 4.76015C7.22039 5.06015 5.06039 6.35015 3.65039 8.29015C2.23039 10.2401 1.71039 12.6201 2.16039 15.0001C2.81039 18.4401 5.56039 21.1902 9.01039 21.8402C9.56039 21.9502 10.1104 22.0002 10.6604 22.0002C12.4704 22.0002 14.2204 21.4402 15.7104 20.3502C17.6504 18.9402 18.9404 16.7801 19.2404 14.4302C19.2904 14.0402 19.1704 13.6501 18.9104 13.3601Z"
          fill="#1F1D36"
        />
      </svg>
    ),
  },
  {
    exact: true,
    path: "/dashboard/document_library",
    component: Document,
    title: "Document Library",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7997 2.21048C15.3897 1.80048 14.6797 2.08048 14.6797 2.65048V6.14048C14.6797 7.60048 15.9197 8.81048 17.4297 8.81048C18.3797 8.82048 19.6997 8.82048 20.8297 8.82048C21.3997 8.82048 21.6997 8.15048 21.2997 7.75048C19.8597 6.30048 17.2797 3.69048 15.7997 2.21048Z"
          fill="#1F1D36"
        />
        <path
          d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19ZM11.5 17.75H7.5C7.09 17.75 6.75 17.41 6.75 17C6.75 16.59 7.09 16.25 7.5 16.25H11.5C11.91 16.25 12.25 16.59 12.25 17C12.25 17.41 11.91 17.75 11.5 17.75ZM13.5 13.75H7.5C7.09 13.75 6.75 13.41 6.75 13C6.75 12.59 7.09 12.25 7.5 12.25H13.5C13.91 12.25 14.25 12.59 14.25 13C14.25 13.41 13.91 13.75 13.5 13.75Z"
          fill="#1F1D36"
        />
      </svg>
    ),
  },
  {
    exact: true,
    path: "/dashboard/fund_raising",
    component: FundRaise,
    title: "Fund Raising",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.1 9.22043C18.29 9.22043 17.55 7.94042 18.45 6.37042C18.97 5.46042 18.66 4.30042 17.75 3.78042L16.02 2.79042C15.23 2.32042 14.21 2.60042 13.74 3.39042L13.63 3.58042C12.73 5.15042 11.25 5.15042 10.34 3.58042L10.23 3.39042C9.78 2.60042 8.76 2.32042 7.97 2.79042L6.24 3.78042C5.33 4.30042 5.02 5.47042 5.54 6.38042C6.45 7.94042 5.71 9.22043 3.9 9.22043C2.86 9.22043 2 10.0704 2 11.1204V12.8804C2 13.9204 2.85 14.7804 3.9 14.7804C5.71 14.7804 6.45 16.0604 5.54 17.6304C5.02 18.5404 5.33 19.7004 6.24 20.2204L7.97 21.2104C8.76 21.6804 9.78 21.4004 10.25 20.6104L10.36 20.4204C11.26 18.8504 12.74 18.8504 13.65 20.4204L13.76 20.6104C14.23 21.4004 15.25 21.6804 16.04 21.2104L17.77 20.2204C18.68 19.7004 18.99 18.5304 18.47 17.6304C17.56 16.0604 18.3 14.7804 20.11 14.7804C21.15 14.7804 22.01 13.9304 22.01 12.8804V11.1204C22 10.0804 21.15 9.22043 20.1 9.22043ZM12 15.2504C10.21 15.2504 8.75 13.7904 8.75 12.0004C8.75 10.2104 10.21 8.75042 12 8.75042C13.79 8.75042 15.25 10.2104 15.25 12.0004C15.25 13.7904 13.79 15.2504 12 15.2504Z"
          fill="#1F1D36"
        />
      </svg>
    ),
  },

  {
    exact: true,
    path: "/dashboard/bank_account",
    component: ComingSoon,
    title: "Bank Account",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.1 9.22043C18.29 9.22043 17.55 7.94042 18.45 6.37042C18.97 5.46042 18.66 4.30042 17.75 3.78042L16.02 2.79042C15.23 2.32042 14.21 2.60042 13.74 3.39042L13.63 3.58042C12.73 5.15042 11.25 5.15042 10.34 3.58042L10.23 3.39042C9.78 2.60042 8.76 2.32042 7.97 2.79042L6.24 3.78042C5.33 4.30042 5.02 5.47042 5.54 6.38042C6.45 7.94042 5.71 9.22043 3.9 9.22043C2.86 9.22043 2 10.0704 2 11.1204V12.8804C2 13.9204 2.85 14.7804 3.9 14.7804C5.71 14.7804 6.45 16.0604 5.54 17.6304C5.02 18.5404 5.33 19.7004 6.24 20.2204L7.97 21.2104C8.76 21.6804 9.78 21.4004 10.25 20.6104L10.36 20.4204C11.26 18.8504 12.74 18.8504 13.65 20.4204L13.76 20.6104C14.23 21.4004 15.25 21.6804 16.04 21.2104L17.77 20.2204C18.68 19.7004 18.99 18.5304 18.47 17.6304C17.56 16.0604 18.3 14.7804 20.11 14.7804C21.15 14.7804 22.01 13.9304 22.01 12.8804V11.1204C22 10.0804 21.15 9.22043 20.1 9.22043ZM12 15.2504C10.21 15.2504 8.75 13.7904 8.75 12.0004C8.75 10.2104 10.21 8.75042 12 8.75042C13.79 8.75042 15.25 10.2104 15.25 12.0004C15.25 13.7904 13.79 15.2504 12 15.2504Z"
          fill="#1F1D36"
        />
      </svg>
    ),
  },
  {
    exact: true,
    path: "/dashboard/setting",
    component: ComingSoon,
    title: "Setting",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.1 9.22043C18.29 9.22043 17.55 7.94042 18.45 6.37042C18.97 5.46042 18.66 4.30042 17.75 3.78042L16.02 2.79042C15.23 2.32042 14.21 2.60042 13.74 3.39042L13.63 3.58042C12.73 5.15042 11.25 5.15042 10.34 3.58042L10.23 3.39042C9.78 2.60042 8.76 2.32042 7.97 2.79042L6.24 3.78042C5.33 4.30042 5.02 5.47042 5.54 6.38042C6.45 7.94042 5.71 9.22043 3.9 9.22043C2.86 9.22043 2 10.0704 2 11.1204V12.8804C2 13.9204 2.85 14.7804 3.9 14.7804C5.71 14.7804 6.45 16.0604 5.54 17.6304C5.02 18.5404 5.33 19.7004 6.24 20.2204L7.97 21.2104C8.76 21.6804 9.78 21.4004 10.25 20.6104L10.36 20.4204C11.26 18.8504 12.74 18.8504 13.65 20.4204L13.76 20.6104C14.23 21.4004 15.25 21.6804 16.04 21.2104L17.77 20.2204C18.68 19.7004 18.99 18.5304 18.47 17.6304C17.56 16.0604 18.3 14.7804 20.11 14.7804C21.15 14.7804 22.01 13.9304 22.01 12.8804V11.1204C22 10.0804 21.15 9.22043 20.1 9.22043ZM12 15.2504C10.21 15.2504 8.75 13.7904 8.75 12.0004C8.75 10.2104 10.21 8.75042 12 8.75042C13.79 8.75042 15.25 10.2104 15.25 12.0004C15.25 13.7904 13.79 15.2504 12 15.2504Z"
          fill="#1F1D36"
        />
      </svg>
    ),
  },
];
