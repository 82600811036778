import React from "react";

const BlackInvestorConnectionCard = ({ title, body }) => {
  return (
    <div>
      <h1>As an Investor</h1>
      <p>
        You receive access to a high-quality pool of investment-grade black founder deal-flow, 
        and assit in shortening the due diligence period by having everything you need from 
        founders in one place and format.
      </p>
      <p>
        For new investors, we provide education on the process, and an automated investment process, 
        that breaks down everything you need to know to understand how to make good investment decisions.
      </p> 
    </div>
  );
};
export default BlackInvestorConnectionCard;
