import React from "react";

const WaitlistFormSelection = ({ setActive, setSelectionType }) => {
  return (
    <div className="WaitlistFormSelection">
      <h1>Join our waitlist</h1>
      <p>How do you want to join us?</p>
      <div className="cards_wrapper">
        <div
          className="card"
          onClick={(e) => {
            setSelectionType("investor");
            setActive(true);
          }}
        >
          <h1>Investor</h1>
          <p>
            I have invested in companies, or am curious and want to learn more about investing in black start up companies.
          </p>
        </div>
        <div
          className="card"
          onClick={(e) => {
            setSelectionType("founder");
            setActive(true);
          }}
        >
          <h1>Founder</h1>
          <p>
            I am a black founder of a company, or I am interested in becoming one.
          </p>
        </div>
      </div>
    </div>
  );
};
export default WaitlistFormSelection;
