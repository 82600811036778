import React, { useState, useRef } from "react";
import ArrowLeft from "../../util/imageIcons/arrow-left.png";

import { useForm } from "react-hook-form";
import base from "../../api/base";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import ThankYou from "./ThankYou";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  hiddenStyle: {
    display: "none"
  }, 
  isVisible: {
    visibility: "block"
  }
}));

const WaitlistForm = ({ setActive, buttonText, showNameField }) => {
  const [thankYouVisibility, setThankYouVisibility] = useState(true);
  const [formVisibility, setFormVisibility] = useState(false);
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const { handleSubmit, register, errors, reset } = useForm();

  const fnameRef = useRef();
  const lnameRef = useRef();
  const companynameRef = useRef();
  const companyurlRef = useRef();
  const emailRef = useRef();
  const addToWaitList = (e) => {
    e.preventDefault();
    const fname = fnameRef.current.value;
    const lname = lnameRef.current.value;
    const companyname = companynameRef.current.value;
    const companyurl = companyurlRef.current.value;
    const email = emailRef.current.value;
    base("Founders").create(
      [
        {
          fields: {
            "First name": fname,
            "Email": email,
            "Last name": lname,
            "Company name": companyname,
            "Company website": companyurl,
          },
        },
      ],
      function (err, record) {
        if (err) {
          console.error(err);
         } else {
          setThankYouVisibility(false)
          setFormVisibility(true)
         }
      }
    );
  };

  const classes = useStyles();

  return (
    <div className="form_wrapper" onSubmit={handleSubmit(addToWaitList)}>
      <div className={thankYouVisibility ? classes.hiddenStyle : classes.isVisible}>
        <ThankYou/>
      </div>
      <h1 className={formVisibility ? classes.hiddenStyle : classes.isVisible}>Join our waitlist</h1>
      <p className={formVisibility ? classes.hiddenStyle : classes.isVisible}>
        Please fill in the detils below so that we can get in contact with you
        about our product.
      </p>
      <form className={formVisibility ?  classes.hiddenStyle : "input_container"}>
        <div className="input_wrapper grid_2">
          <label htmlFor="fname">First Name</label>

          <TextField
            variant="outlined"
            type="text"
            error={errors.name ? true : false}
            helperText={errors.name && errors.name.message}
            fullWidth={true}
            inputRef={
              (register({
                required: "Please enter your name",
              }),
              fnameRef)
            }
          />
        </div>
        <div className="input_wrapper grid_2">
          <label htmlFor="lname">Last Name</label>

          <TextField
            variant="outlined"
            type="text"
            error={errors.email ? true : false}
            helperText={errors.email && errors.email.message}
            fullWidth={true}
            inputRef={
              (register({
                required: "Please enter your email",
              }),
              lnameRef)
            }
          />
        </div>
        <div className="input_wrapper grid_2">
          <label htmlFor="cname">Company Name</label>
          <TextField
            variant="outlined"
            type="text"
            error={errors.email ? true : false}
            helperText={errors.email && errors.email.message}
            fullWidth={true}
            inputRef={
              (register({
                required: "Please enter your email",
              }),
              companynameRef)
            }
          />
        </div>
        <div className="input_wrapper grid_2">
          <label htmlFor="cweb">Company website</label>
          <TextField
            variant="outlined"
            type="url"
            error={errors.email ? true : false}
            helperText={errors.email && errors.email.message}
            fullWidth={true}
            inputRef={
              (register({
                required: "Please enter your email",
              }),
              companyurlRef)
            }
          />
        </div>
        <div className="input_wrapper grid_4">
          <label htmlFor="Email">Email</label>
          <TextField
            variant="outlined"
            type="email"
            error={errors.email ? true : false}
            helperText={errors.email && errors.email.message}
            fullWidth={true}
            inputRef={
              (register({
                required: "Please enter your email",
              }),
              emailRef)
            }
          />
        </div>
        <div onClick={(e) => setActive(false)}>
          <img src={ArrowLeft} alt="" />
        </div>
        <button className="grid_3 join_button" onClick={addToWaitList}>
          {buttonText}
        </button>
      </form>
    </div>
  );
};
export default WaitlistForm;
