import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import StatsSection from "./../components/StatsSection";
import TeamBiosSection from "./../components/TeamBiosSection";
import CtaSection from "./../components/CtaSection";
import WhyPitchBlack from "../components/custom/WhyPitchBlack";
import BannerSmall from "../components/custom/BannerSmall";
import Footer from "../components/custom/Footer";
import Header from "../components/custom/Header";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <Header need_nav="no" />
      <WhyPitchBlack/>
       <StatsSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />
      {/* <TeamBiosSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
      />  */}
      <BannerSmall />

      {/* footer */}
      <Footer />
    </>
  );
}

export default AboutPage;
